import styled from '@grebban/style-system-react';
import Background from '@sportson/core-web/components/Background';
import { type BackgroundColorProps } from '@sportson/core-web/components/BackgroundColor';
import { type BackgroundImageProps } from '@sportson/core-web/components/BackgroundImage';
import { type BackgroundVideoProps } from '@sportson/core-web/components/BackgroundVideo';
import { type ButtonProps } from 'components/Button';
import { type CssColorKey } from 'config/branding/colors';
import { type CssVariable } from 'config/branding/types';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import { type WordpressACFWysiwyg } from 'libs/wordpress/types/acf/fields';
import { above } from '@sportson/core-web/utils/mediaqueries';
import Button from 'components/Button';
import Text from 'components/Text';

const ContentWrapper = styled('div')`
    color: var(--color-base-white);
    display: flex;
    flex-direction: column;
    position: relative;
    width: ${({ isHero }) => (isHero ? '100vw;' : '100%')};
    height: 100%;
`;

const BackgroundWrapper = styled('div')`
    position: absolute;
    display: flex;
    inset: 0;
    mix-blend-mode: multiply;
    padding: inherit;
    z-index: var(--zindex-background);
`;
const StyledBackground = styled(Background)`
    position: relative;
    flex: 1;
    overflow: hidden;
`;

const TextContentContainer = styled('div')`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
`;

const TextContentWrapper = styled('div')`
    display: flex;
    background-color: ${({ displayTextContentBackground }) =>
        displayTextContentBackground ? 'rgba(0, 0, 0, 0.5)' : 'transparent'};
    backdrop-filter: ${({ displayTextContentBackground }) => (displayTextContentBackground ? 'blur(6px)' : 'unset')};

    ${above['tablet.md']} {
        width: 100%;
        max-width: 100%;
    }
`;

const TextPanelWrapper = styled('div')`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 1fr;
    width: 100%;
    max-width: 100vw;
    min-height: 200px;
    height: fit-content;
    row-gap: 16px;
    width: 100%;
    max-width: 100vw;
    min-height: 200px;
    height: fit-content;
    row-gap: 16px;

    ${above['desktop.lg']} {
        ${({ isHero }) =>
            isHero &&
            `
            max-width: var(--site-corridor-max-width) ;
            margin: 0 auto;
    }`}
    }
`;

const TextContent = styled('div')`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-column: 1 / span 12;
`;

const ButtonWrapper = styled('div')`
    display: flex;
    flex-direction: row;
    height: 100%;
    gap: 12px;
    grid-column: 1;

    ${above['tablet.md']} {
        justify-content: flex-end;
        align-items: flex-end;

        ${({ gridColumnProps }) => gridColumnProps && `grid-column: ${gridColumnProps};`}
    }

    @media (min-width: 2001px) {
        padding: ${({ isHero }) => (isHero ? '0 24px' : '0')};
    }
`;

export interface PanelComponentPropsHeading {
    text: WordpressACFWysiwyg;
    type?: 'default' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    color?: CssVariable<CssColorKey>;
}

export interface PanelComponentPropsParagraph {
    text: WordpressACFWysiwyg;
    color?: CssVariable<CssColorKey>;
}

export type PanelComponentAlignmentProps = 'flex-start' | 'center' | 'flex-end';
export type PanelTextAlignmentProps = 'left' | 'center' | 'right';

// TODO: Remove Record<string, unknown> when styling is fixed, correctly pass css via rest
export interface PanelComponentProps extends Record<string, unknown> {
    backgroundColor?: BackgroundColorProps;
    backgroundImage?: BackgroundImageProps;
    backgroundVideo?: BackgroundVideoProps;
    heading?: PanelComponentPropsHeading;
    buttons?: ButtonProps[];
    paragraph?: PanelComponentPropsParagraph;
    tag?: string;
    contentLink?: string;
    contentHorizontalAlignment?: PanelComponentAlignmentProps;
    enableTextContentBackground?: boolean;
    contentTextColor?: CssVariable<CssColorKey>;
    isHero?: boolean;
}

const horizontalAlignmentToTextAlignment: Record<PanelComponentAlignmentProps, string> = {
    'flex-start': 'left',
    center: 'center',
    'flex-end': 'right',
};

const horizontalAlignmentToGridColumn: Record<PanelTextAlignmentProps, {}> = {
    left: { textContent: '1 / span 6', buttonWrapper: '7 / span 6' },
    center: { textContent: '4 / span 6', buttonWrapper: '11 / span 6' },
    right: { textContent: '7 / span 6', buttonWrapper: '1 / span 1' },
};

const PanelComponent = ({
    backgroundColor,
    backgroundImage,
    backgroundVideo,
    heading,
    buttons,
    paragraph,
    tag,
    contentLink,
    contentHorizontalAlignment = 'flex-start',
    enableTextContentBackground = true,
    contentTextColor,
    isHero,
    ...rest
}: PanelComponentProps) => {
    const textAlignment = horizontalAlignmentToTextAlignment[contentHorizontalAlignment];
    const gridColumn = horizontalAlignmentToGridColumn[textAlignment];

    return (
        <ContentWrapper>
            <BackgroundWrapper>
                <StyledBackground
                    backgroundVideo={backgroundVideo}
                    backgroundImage={backgroundImage}
                    backgroundColor={backgroundColor}
                    lazy={false}
                />
            </BackgroundWrapper>

            <TextContentContainer justifyContent={contentHorizontalAlignment}>
                <TextContentWrapper
                    displayTextContentBackground={enableTextContentBackground}
                    p={['16px', null, null, '24px 40px 40px']}
                    {...rest}
                    isHero={isHero}
                >
                    <TextPanelWrapper isHero={isHero}>
                        <TextContent
                            gridColumnProps={gridColumn.textContent}
                            textAlignment={textAlignment}
                            gap={['16px', null, null, '8px']}
                            isHero={isHero}
                        >
                            {tag && (
                                <Text
                                    $as="p"
                                    color={contentTextColor || 'var(--cta-module-text-color-default)'}
                                    typography="UI/12_100_0_450_uppercase"
                                    padding="8px 0"
                                >
                                    {tag}
                                </Text>
                            )}

                            {/* TODO: Add logic for heading size */}
                            {heading && (
                                <Wysiwyg
                                    data={heading.text}
                                    TextComponent={(props) => (
                                        <Text
                                            textAlign="left"
                                            as={heading.type || 'h3'}
                                            color={
                                                heading.color ||
                                                contentTextColor ||
                                                'var(--cta-module-text-color-default)'
                                            }
                                            typography={[
                                                'Content/32_130_0_500',
                                                null,
                                                null,
                                                null,
                                                null,
                                                'Content/40_120_0_500',
                                            ]}
                                            {...props}
                                        />
                                    )}
                                />
                            )}
                            {paragraph && (
                                <Wysiwyg
                                    data={paragraph.text}
                                    TextComponent={(props) => (
                                        <Text
                                            textAlign="left"
                                            $as="p"
                                            color={
                                                paragraph.color ||
                                                contentTextColor ||
                                                'var(--cta-module-text-color-default)'
                                            }
                                            typography="Content/18_150_0_350"
                                            {...props}
                                        />
                                    )}
                                />
                            )}
                        </TextContent>

                        {buttons && (
                            <ButtonWrapper gridColumnProps={gridColumn.buttonWrapper} isHero={isHero}>
                                {buttons.map((button) => (
                                    <Button
                                        key={`${button.url}`}
                                        typography="UI/16_100_0_450"
                                        theme={button.theme}
                                        to={button.url}
                                    >
                                        {button.children}
                                    </Button>
                                ))}
                            </ButtonWrapper>
                        )}
                    </TextPanelWrapper>
                </TextContentWrapper>
            </TextContentContainer>
        </ContentWrapper>
    );
};

export default PanelComponent;
