import { type BackgroundColorProps } from '@sportson/core-web/components/BackgroundColor';
import { type BackgroundImageProps } from '@sportson/core-web/components/BackgroundImage';
import { type BackgroundVideoProps } from '@sportson/core-web/components/BackgroundVideo';
import PanelComponent, { type PanelComponentAlignmentProps, type PanelComponentProps } from 'content/Panel';
import useBreakpoint from '@sportson/core-web/hooks/useBreakpoint';
import React from 'react';

import { type WordpressTag } from 'libs/wordpress/types/contentSystem/elements';
import {
    type WordpressBackgroundSelection,
    type WordpressFlexibleNavigation,
    type WordpressHeadingGroup,
    type WordpressParagraphGroup,
} from 'libs/wordpress/types/contentSystem/groups';
import {
    type WordpressHorizontalAlignment,
    type WordpressTextColor,
} from 'libs/wordpress/types/contentSystem/settings';
import { transformBackgroundGroup } from 'libs/wordpress/utils/transformBackground';
import { transformLinkButton } from 'libs/wordpress/utils/transformButton';
import { transformHeadingGroup } from 'libs/wordpress/utils/transformHeading';
import { transformParagraphGroup } from 'libs/wordpress/utils/transformParagraph';
import transformTextColor from 'libs/wordpress/utils/transformTextColor';
import { ArrowIcon } from 'assets/media/icons/ArrowIcon';

export interface WordpressPanelBlock {
    settings: Array<'tag' | 'heading' | 'paragraph' | 'link'>;
    textColor: WordpressTextColor;
    contentHorizontalAlignment: WordpressHorizontalAlignment;
    background: WordpressBackgroundSelection;
    tag?: WordpressTag;
    heading?: WordpressHeadingGroup;
    paragraph?: WordpressParagraphGroup;
    link?: WordpressFlexibleNavigation;
    textContentBackgroundBlur?: boolean;
}
// TODO: Remove Record<string, unknown> when styling is fixed, correctly pass css via rest
export interface PanelBlockProps extends Record<string, unknown> {
    data: WordpressPanelBlock;
    isHero?: boolean;
    layout?: 'panel';
}

const hAlignmentMapper: Record<WordpressHorizontalAlignment, PanelComponentAlignmentProps | undefined> = {
    default: undefined,
    right: 'flex-end',
    center: 'center',
    left: 'flex-start',
};

const PanelBlock: React.FunctionComponent<PanelBlockProps> = ({ data, layout, isHero, ...rest }) => {
    const isMobile = useBreakpoint({ to: 'tablet.sm' });
    const device = isMobile ? 'mobile' : 'desktop';
    const isSlider = rest?.isSlider ?? false;

    const componentData: PanelComponentProps = {
        contentHorizontalAlignment: hAlignmentMapper[data?.contentHorizontalAlignment],
        enableTextContentBackground: data.textContentBackgroundBlur,
    };

    // Background
    if (data.background.desktop) {
        const { type: backgroundType, data: backgroundData } = transformBackgroundGroup(data.background, device);
        if (backgroundType === 'color') {
            componentData.backgroundColor = backgroundData as BackgroundColorProps;
        } else if (backgroundType === 'image') {
            componentData.backgroundImage = backgroundData as BackgroundImageProps;
        } else if (backgroundType === 'video') {
            componentData.backgroundVideo = backgroundData as BackgroundVideoProps;
        }
    }

    // Background
    if (data.background.mobile) {
        const { type: backgroundType, data: backgroundData } = transformBackgroundGroup(data.background, device);
        if (backgroundType === 'color') {
            componentData.backgroundColor = backgroundData as BackgroundColorProps;
        } else if (backgroundType === 'image') {
            componentData.backgroundImage = backgroundData as BackgroundImageProps;
        } else if (backgroundType === 'video') {
            componentData.backgroundVideo = backgroundData as BackgroundVideoProps;
        }
    }

    // Heading
    if (data.settings.includes('heading') && data.heading) {
        componentData.heading = transformHeadingGroup(data.heading, device);
    }

    // Link
    if (data.settings.includes('link') && data.link) {
        const linkType = data.link[0].layout;
        if (linkType === 'buttons') {
            componentData.buttons = data.link[0].buttons.map((button) => {
                const tButton = transformLinkButton(button.button);
                return {
                    url: tButton.link,
                    children: (
                        <>
                            {tButton.label}
                            <ArrowIcon />
                        </>
                    ),
                    theme: tButton.theme,
                };
            });
        } else if (linkType === 'content') {
            componentData.contentLink = data.link[0].link;
        }
    }

    // Tag
    if (data.settings.includes('tag') && data.tag) {
        componentData.tag = data.tag;
    }

    // Text
    if (data.settings.includes('paragraph') && data.paragraph) {
        componentData.paragraph = transformParagraphGroup(data.paragraph, device);
    }

    // Text Color
    if (data.textColor) {
        componentData.contentTextColor = transformTextColor(data.textColor);
    }

    // TODO: Not sure this is the right approach to define padding-bottom for the slider
    return (
        <PanelComponent {...componentData} {...rest} pb={isSlider && ['76px', null, null, '80px']} isHero={isHero} />
    );
};

export default PanelBlock;
